/* modified from https://github.com/skratchdot/react-github-corner/blob/master/src/lib/get-github-corner-styles.js */

.gh-corner {
	--fg: #fff;
	--bg: #151513;

	position: absolute;
	top: 0;
	right: 0;
}

.gh-corner__click-target-transparent {
	fill: transparent;
}

.gh-corner__link:hover .gh-corner__octo-arm {
	animation: octocat-wave 560ms ease-in-out;
}

.gh-corner__octo-body,
.gh-corner__octo-arm {
	fill: var(--fg);
}

.gh-corner__octo-banner {
	fill: var(--bg);
}

.gh-corner__octo-arm {
	transform-origin: 130px 106px;
}

@keyframes octocat-wave {
	0%,
	100% {
		transform: rotate(0deg);
	}
	20%,
	60% {
		transform: rotate(-25deg);
	}
	40%,
	80% {
		transform: rotate(10deg);
	}
}

@media (max-width: 500px) {
	.gh-corner__link:hover .gh-corner__octo-arm {
		animation: none;
	}
	.gh-corner__octo-arm {
		animation: octocat-wave 560ms ease-in-out;
	}
}
